import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "@ui/wrapper";
import SectionTitle from "@ui/section-title";
import Button from "@ui/button";
import {
  LanguageGuidesWrapper,
  LanguageGuidesInner,
  LanguageGuidesBtns,
  ButtonWrap
} from "./style";
const LanguageGuides = ({ data, layout, ...props }) => {

  return (
    <LanguageGuidesWrapper layout={layout} {...props} id={data.section}>
      <LanguageGuidesInner>
        <Container>
          <Row>
            <Col>
              <SectionTitle
                subtitle={data.section_title?.subtitle}
                title={data.section_title?.title}
                description={data.section_title?.description}
              />
              <LanguageGuidesBtns className="languageBtns">
                {data.buttons &&
                data?.buttons?.map(({ id, content, ...rest }) => (
                  <Button key={id} m="7px" {...rest}>
                    {content}
                  </Button>
                ))}
              </LanguageGuidesBtns>
            </Col>
          </Row>
          {data.items &&
              data?.items?.map(({ id, name, ...rest }) => (
                <ButtonWrap key={id}>
                  <Button className="learnMoreBtn" key={id} m="7px" {...rest}>
                    {name}
                  </Button>
                </ButtonWrap>
              ))
            }
        </Container>
      </LanguageGuidesInner>
    </LanguageGuidesWrapper>
  );
};

LanguageGuides.propTypes = {
  layout: PropTypes.oneOf([1, 2, 3, 4, 5]),
  items: PropTypes.object,
};
LanguageGuides.defaultProps = {
  layout: 1,
};

export default LanguageGuides;
