import styled, { css, device, themeGet } from "@styled";
import blueGradientBg from "@data/images/bg/bluegradientbg.png";

export const LanguageGuidesWrapper = styled.section`
  background: #571883;
  background: linear-gradient(
    320deg,
    ${themeGet("colors.secondaryDark")},
    ${themeGet("colors.secondary")}
  );
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 100px 0 20px;

  .section-title {
    h2 {
      color: #f5491d;
    }
    p {
      color: #fff;
    }
  }
  a {
    border-radius: 4px;
    background: #fff;
    border-color: #e2e2e2;
    font-size: 17px;
  }

  ${(props) =>
    props.layout === 2 &&
    css`
      background: url(${blueGradientBg});
      background-position: center top;
      background-size: cover;
      background-repeat: no-repeat;
      padding: 100px 0;

      .section-title {
        @media (min-width: 1200px) {
          margin-bottom: 40px;
        }
        @media (min-width: 1600px) {
          margin-bottom: 50px;
        }

        h6, h2 {
          color: #fa4f19;
        }
        p {
          color: #fff;
        }
      }
      a, button {
        border-radius: 4px;
        background: rgb(255 255 255 / 11%);
        border-color: rgb(255 255 255 / 41%);
        color: #fff;
        &:hover {
          color: #fff;
        }

        @media (max-width: 768px) {
          font-size: 16px;
        }

        @media (max-width: 576px) {
          font-size: 15px;
        }
      }
    `}

    ${(props) =>
      props.layout === 5 &&
      css`
        background: #f94f19;
        padding: 100px 0;
  
        .section-title {
          @media (min-width: 1200px) {
            margin-bottom: 40px;
          }
          @media (min-width: 1600px) {
            margin-bottom: 50px;
          }
          h2 {
            border-color: #fff;
          }
  
          h6, h2 {
            color: #333333;
          }
          p {
            color: #fff;
          }
        }
        a, button {
          border-radius: 4px;
          background: rgb(255 255 255 / 11%);
          border-color: rgb(255 255 255 / 41%);
          color: #fff;
          &:hover {
            color: #fff;
          }
  
          @media (max-width: 768px) {
            font-size: 16px;
          }
  
          @media (max-width: 576px) {
            font-size: 15px;
          }
        }
        
        a.learnMoreBtn {
          border-radius: 15px;
          font-weight: 500;
        }

        .languageBtns {
          grid-template-columns: repeat(1, 1fr);
            ${device.small} {
              grid-template-columns: repeat(2, 1fr);
            }
            ${device.medium} {
              grid-template-columns: repeat(3, 1fr);
            }
            ${device.large} {
              grid-template-columns: repeat(4, 1fr);
            }
        }
      `}
`;

export const LanguageGuidesInner = styled.div``;

export const LanguageGuidesBtns = styled.div`
  margin-left: -7px;
  margin-right: -7px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 5px 10px;
  margin-top: 50px;
  ${device.small} {
    grid-template-columns: repeat(1, 1fr);
  }
  ${device.medium} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${device.large} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const ButtonWrap = styled.div`
    margin-block-start: 40px;
    text-align: center;

    a {
      background-color: #fff;
      border-color: #fff;
      color: #333;

      &:hover {
        color: #333;
      }
    }
`;
